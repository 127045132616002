import React from 'react';
import MotionAnimatedCounter from '../../../animated-counter';
import ParallaxBackground from '../../../parallax-background';
import { SectionHeading } from '../../../section-heading';
import SectionTitle from '../../../section-title';
import * as style from './index.module.scss';

export default function ByTheNumbers() {
  return (
    <section
      className={
        style.byTheNumbers +
        ' m-auto d-block d-md-flex flex-column align-items-center justify-content-center'
      }
    >
      <div className={style.fluidContainer + ' container-fluid'}>
        <div className="container">
          <SectionHeading className={`${style.title} ${style.white} text-center`}>
            Nuborrow by the numbers
          </SectionHeading>
          <div className={style.numbers + ' d-grid text-md-center'}>
            <div className={`${style.numberItem} ${style.item1} d-flex flex-column`}>
              <SectionTitle className={`${style.white}`}>
                <MotionAnimatedCounter value={15000} suffix={'+'} duration={2000} />
              </SectionTitle>
              <div className={''}>Applications Processed</div>
            </div>

            <div className={`${style.numberItem} ${style.item2} d-flex flex-column`}>
              <SectionTitle className={`${style.white}`}>
                <MotionAnimatedCounter
                  prefix={'$'}
                  value={700}
                  suffix={' Million+'}
                  duration={2000}
                />
              </SectionTitle>
              <div className={''}>Mortgage Loans Funded</div>
            </div>

            <div className={`${style.numberItem} ${style.item3} d-flex flex-column`}>
              <SectionTitle className={`${style.white}`}>
                <MotionAnimatedCounter value={1200} suffix={'+'} duration={2000} />
              </SectionTitle>
              <div className={''}>5 star reviews</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-2 d-none d-md-block">
        <ParallaxBackground className={style.imgWrapper} />
      </div>
    </section>
  );
}
