// extracted by mini-css-extract-plugin
export var byTheNumbers = "index-module--by-the-numbers--6oEy9";
export var fluidContainer = "index-module--fluid-container--VFE3u";
export var imgWrapper = "index-module--img-wrapper--1PnY1";
export var item1 = "index-module--item1--+NRr1";
export var item2 = "index-module--item2--uGuF-";
export var item3 = "index-module--item3--CoaSg";
export var numberItem = "index-module--number-item--hLvsP";
export var numbers = "index-module--numbers--BJQd0";
export var title = "index-module--title--dm9gd";
export var white = "index-module--white--4SP7U";