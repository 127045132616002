import React from 'react';
import AdamSign from '../../../../images/adam-ross-sign.svg';
import { SectionDescription } from '../../../section-description';
import VisualTitle from '../../../visual-title';
import * as style from './index.module.scss';

export default function MissionFull() {
  return (
    <section
      className={
        style.mission +
        ' m-auto d-block d-md-flex flex-column align-items-center justify-content-center'
      }
    >
      {/* <div className={style.statement + ' container d-flex flex-column justify-content-center'}>
        <div className="wrapper col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-1 offset-xl-2">
          <SectionSubheading>Our Mission</SectionSubheading>
          <SectionTitle className={style.statementTitle}>
            I know from experience that borrowing money without a plan — is a bad plan.
            <br />
            <br />
            That's why we believe in education, tools, and the "why" behind each mortgage we do.
          </SectionTitle>
        </div>
      </div> */}

      <div
        className={
          style.newStatement +
          ' container position-relative h-100 d-flex flex-column align-items-center justify-content-center'
        }
      >
        <div className="wrapper text-center d-flex flex-column align-items-center justify-content-center">
          <VisualTitle>"We started this company after making mistakes"</VisualTitle>
        </div>
      </div>

      <div className="explanation container">
        <div className="wrapper d-flex">
          <div className={style.pictureBlock + ' d-none d-sm-block col-4'}></div>
          <div className={' col-12 col-sm-8 offset-sm-0 col-md-8 offset-md-0'}>
            <SectionDescription>
              The truth is, the mortgage world can be riddled with mistakes, misconceptions, and
              financial failure. I know, that isn't exactly a positive opening statement, but over
              the last 20 years I've come to realize the common mistakes I've made, and I continue
              to see clients make, can be avoided.
              <br />
              <br />
              10 years ago I set out to change the mortgage process, to make it faster and easier,
              and to be honest, it remains a constant work in progress. Sure, things are faster than
              8 years ago, but we continue to push lending and legal partners to move faster and
              look at each client more than a credit score.
              <br />
              <br />
              I promise you we aren't close to finished when it comes to what we do best and our
              team will continue to build the largest community of self-educated homeowners. The
              real Nuborrow advantage is the proven process we've developed that shows homeowners
              how to harness the power of home equity to improve their own financial health -
              something our team believes so strongly in we customize this process for each client.
              <br />
              <br />I look forward to showing you how to unlock the equity in your home to renovate,
              save money, pay off debts, or do all three and improve your credit health.
            </SectionDescription>
            <div className={style.signatureBlock + ' d-flex align-items-end'}>
              <div className={style.signatureWrapper}>
                <img src={AdamSign} alt="Adam Ross" className="signature" />
                {/* <img src={AdamTitle} alt="Principal Broker" className={style.adamTitle} /> */}
                <div className="text-center mb-4">Nuborrow President</div>
              </div>

              <div className={style.pictureBlock + ' d-block d-sm-none'}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
