import React from 'react';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './index.module.scss';

export default function OurMission() {
  const data: FeatureSectionProps = {
    items: [
      {
        title: 'We believe education is the key to success.',
        subtitle: 'Our Mission',
        description: (
          <>
            <span>
              Education and the tools homeowners need to make decisions that improve their future
              is behind everything we do.
            </span>
            <br />
            <br />
            <span>
              At Nuborrow, we will not stop until every homeowner receives education, and the
              guidance to unlock the equity in their home to safely improve their financial health.
            </span>
          </>
        ),
        art: ourMissionArt(),
        actions: []
      }
    ]
  };

  return <FeatureSection {...data} />;
}

const ourMissionArt = () => {
  return (
    <div
      className={style.wrapper + ' d-flex flex-column align-items-center justify-content-center'}
    >
      <ParallaxBackground className={style.heroImage} />
    </div>
  );
};
