import React, { useEffect, useState } from 'react';
import LogoCmlsFinancial from '../../../../images/optimized/logos_cmls_financial.svg';
import LogoCommunityTrust from '../../../../images/optimized/logos_community_trust.svg';
import LogoConcentra from '../../../../images/optimized/logos_concentra.svg';
import LogoCwbOptimumMortgage from '../../../../images/optimized/logos_cwb_optimum_mortgage.svg';
import LogoLogosEquitableBank from '../../../../images/optimized/logos_equitable_bank.svg';
import LogoFirstNationalFinancial from '../../../../images/optimized/logos_first_national_financial.svg';
import LogoHaventreeBank from '../../../../images/optimized/logos_haventree_bank.svg';
import LogoHomeTrust from '../../../../images/optimized/logos_home_trust.svg';
import LogoIciciBank from '../../../../images/optimized/logos_icici_bank.svg';
import LogoLaurentianBank from '../../../../images/optimized/logos_laurentian_bank.svg';
import LogoManulifeBank from '../../../../images/optimized/logos_manulife_bank.svg';
import LogoMcapRmg from '../../../../images/optimized/logos_mcap_rmg.svg';
import LogoScotiaBank from '../../../../images/optimized/logos_scotiabank.svg';
import LogoTdBank from '../../../../images/optimized/logos_td_bank.svg';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import * as style from './index.module.scss';

const logos = [
  LogoScotiaBank,
  LogoTdBank,
  LogoConcentra,
  LogoLaurentianBank,
  LogoManulifeBank,
  LogoLogosEquitableBank,
  LogoCmlsFinancial,
  LogoIciciBank,
  LogoCwbOptimumMortgage,
  LogoHomeTrust,
  LogoMcapRmg,
  LogoFirstNationalFinancial,
  LogoHaventreeBank,
  LogoCommunityTrust
];

export default function AboutUsFeatures() {
  let timer: number | NodeJS.Timer = 0;
  const [highlighted, setHighlighted] = useState(0);

  useEffect(() => {
    timer = setInterval(() => setHighlighted(Math.floor(Math.random() * logos.length)), 4000);

    () => clearInterval(timer as number);
  }, []);

  const data: FeatureSectionProps = {
    items: [
      {
        title: 'Tested and trusted lending partners.',
        subtitle: 'Unlimited lending options ',
        description: `Interest rates, product updates, and constant program changes can be a headache for homeowners to track.  Let our team worry about finding the best option(s) for you and your family — all you have to do is choose the right fit — it's that easy.`,
        art: partnersArt(highlighted),
        actions: [{ label: `Let's Start Saving`, href: 'https://apply.nuborrow.com', isPrimary: true }]
      }
      // {
      //   align: 'right',
      //   title: 'Join the team and be apart of something big.',
      //   description: `Thriving industry. Growing city. Booming company. We're in the Canadian mortgage industry, which is worth over $1.43 Trillion (and growing). Speaking of growth, we are always looking for energetic, helpful superstars to grow with us!`,
      //   art: joinTeam(),
      //   actions: [{ label: `Join the team`, href: '', isPrimary: true }]
      // }
    ]
  };

  return <FeatureSection {...data} />;
}

const partnersArt = (highlighted: number) => {
  return (
    <div className={style.wrapper + ' '}>
      <div className={style.logoGrid}>
        {logos.map((logo, index) => (
          <img
            className={`${style.svgLogo} ${highlighted === index ? style.highlight : ''}`}
            src={logo}
            alt="logo"
            key={'logo-' + index}
          />
        ))}

        {/* <img className={style.svgLogo} src={LogoTdBank} alt="Td Bank" />
        <img className={style.svgLogo} src={LogoConcentra} alt="Concentra" />
        <img className={style.svgLogo} src={LogoLaurentianBank} alt="Laurentian Bank" />
        <img className={style.svgLogo} src={LogoManulifeBank} alt="Manulife Bank" />
        <img className={style.svgLogo} src={LogoLogosEquitableBank} alt="Logos Equitable Bank" />
        <img className={style.svgLogo} src={LogoCmlsFinancial} alt="Cmls Financial" />
        <img className={style.svgLogo} src={LogoIciciBank} alt="Icici Bank" />
        <img className={style.svgLogo} src={LogoCwbOptimumMortgage} alt="Cwb Optimum Mortgage" />
        <img className={style.svgLogo} src={LogoHomeTrust} alt="Home Trust" />
        <img className={style.svgLogo} src={LogoMcapRmg} alt="Mcap Rmg" />
        <img
          className={style.svgLogo}
          src={LogoFirstNationalFinancial}
          alt="First National Financial"
        />
        <img className={style.svgLogo} src={LogoHaventreeBank} alt="Haventree Bank" />
        <img className={style.svgLogo} src={LogoCommunityTrust} alt="Community Trust" /> */}
      </div>
    </div>
  );
};
