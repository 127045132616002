import React from 'react';
import BannerSection from '../components/banner-section';
import CallToAction from '../components/call-to-action';
import { CallToActionCardProps } from '../components/call-to-action/models';
import ContactSection from '../components/contact-section';
import Layout from '../components/layout';
import ByTheNumbers from '../components/sections/about/by-the-numbers';
import AboutUsFeatures from '../components/sections/about/features';
import MissionFull from '../components/sections/about/mission-full';
import OurMission from '../components/sections/about/misson';
import Reviews from '../components/sections/shared/reviews';

export default function AboutUsPage() {
  const callToActionCards: CallToActionCardProps[] = [
    {
      title: 'Join a winning team.',
      actionLabel: 'Start your new career',
      actionHref: '/about#contactus'
    },
    {
      title: 'Want to know more about Nuborrow?',
      actionLabel: 'Press room',
      actionHref: '/soon#news'
    }
  ];

  const ctaTitle = <>Join the Nuborrow team.</>;

  const bannerTitle = `"Borrowing money without a plan, is a bad plan"`;
  const bannerDesc = (
    <>
      Your home is more than your biggest investment, it's where you and your family create
      memories.
      <br />
      This is why at Nuborrow we create customized mortgage plans to help you reach your financial
      goals.
      <br />
      <br />
      Adam Ross — President, Nuborrow
    </>
  );

  return (
    <Layout pageTitle={'About'}>
      <BannerSection title={bannerTitle} description={bannerDesc} />
      <OurMission />
      <MissionFull />
      <ByTheNumbers />
      <Reviews />
      <AboutUsFeatures />
      <ContactSection />
      <CallToAction title={ctaTitle} cards={callToActionCards} />
    </Layout>
  );
}
