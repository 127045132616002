import { BsFillStarFill } from '@react-icons/all-files/bs/BsFillStarFill';
import { motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { ReviewViewQuery } from '../../../../../graphql-types';
import LogoGoogle from '../../../../images/optimized/logos_google.svg';
import Avatar from '../../../avatar';
import { MotionBasicCard } from '../../../basic-card';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import * as style from './index.module.scss';
import { Review } from './model';

export default function Reviews() {
  const data: FeatureSectionProps = {
    title: 'Join the movement.',
    fullLengthTitle: true,
    items: [
      {
        align: 'left',
        title: `The (almost) 5-Star mortgage company.`,
        subtitle: 'Clients give Nuborrow 98% happiness rating!',
        description: `We are so proud of our team and love client feedback, but don't take our word for it. Read the reviews or watch the video testimonials, to see what our clients have to say.`,
        actions: [
          { label: `Apply For Free`, href: 'https://apply.nuborrow.com', isPrimary: true },
          {
            label: `Read more reviews`,
            href: 'https://www.google.com/maps/place/Nuborrow+-+Mortgage+Broker/@43.8350235,-79.5060492,15z/data=!4m7!3m6!1s0x0:0xf95300d3acb3dcec!8m2!3d43.8350755!4d-79.5057733!9m1!1b1',
            hrefTarget: '_blank'
          }
        ],
        art: reviewsArt()
      }
    ]
  };

  return <FeatureSection {...data} />;
}

const reviewsArt = () => {
  const avatars = useStaticQuery<ReviewViewQuery>(dataQuery);
  const [containerRef, inView] = useInView({ threshold: 0.15 });

  const tweets: Review[] = [
    {
      rating: 5,
      fullName: 'Tracy Weiler',
      positive: 'Professionalism, Responsiveness',
      text: `We had a great experience working with Rob. He responded to all our questions quickly and efficiently. He is very friendly, helpful, knowledgeable and professional through the whole process. Highly recommended!`
    },
    {
      rating: 5,
      fullName: 'Larry Pearce',
      positive: 'Professionalism, Quality, Responsiveness, Value',
      text: `From the moment I applied on line, I received my call the next day from a friendly and knowledgeable person. He worked with us through the process of securing the mortgage to purchase the home we already live in.`
    },
    {
      rating: 5,
      fullName: 'Jamie Andre',
      text: `This was the most refreshing financial experience I have had thus far in my years of owning multiple properties. Nuborrow took the time to cater options that suited my needs and walked me through how to not only help mine and my families financial health but also learn how to use the equity in my home to my benefit.`
    }
  ];

  const variants = {
    visible: {
      opacity: 1,
      transition: { duration: 0.1, staggerChildren: 0.2 }
    },
    invisible: {
      opacity: 0.98
    }
  };

  const childVariants = {
    visible: {
      opacity: 1,
      translateX: '0%',
      transition: { duration: 0.4 }
    },
    invisible: {
      opacity: 0,
      translateX: '33%'
    }
  };

  let animation = inView ? 'visible' : 'invisible';

  avatars.allFile.nodes.forEach((file, index) => {
    tweets[index].avatar = file as unknown as ImageDataLike;
  });

  return (
    <motion.div ref={containerRef} variants={variants} animate={animation} data-test={animation}>
      {tweets.map((r) => (
        <MotionBasicCard key={r.fullName} className={style.card} variants={childVariants}>
          <Avatar file={r.avatar!} alt={r.fullName} className={style.avatar} />
          <h3>{r.fullName}</h3>
          <h4>{getStarRating(r.rating ?? 0)}</h4>
          {r.positive && (
            <p className={style.positive}>
              <b>Positive:</b> {r.positive}
            </p>
          )}
          <p className={style.text}>{r.text}</p>
          <div className={style.brandLogo}>
            <img src={LogoGoogle} width={100} alt="Google Logo" />
          </div>
        </MotionBasicCard>
      ))}
    </motion.div>
  );
};

const getStarRating = (rating: number) => {
  const stars: JSX.Element[] = [];

  for (let i = 0; i < rating; i++) {
    stars.push(<BsFillStarFill key={`star-${i}`} />);
  }

  return stars;
};

const dataQuery = graphql`
  query ReviewView {
    allFile(limit: 3, skip: 2, filter: { relativePath: { glob: "person-*.jpg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, aspectRatio: 1, width: 48)
        }
      }
    }
  }
`;
